<template>
    <div>
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">
                        {{ $t('send_mail_to_customer')}} : {{ customer.fullname }}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body p-0">
                  <div class="form-group row">
                    <div class="col-6">
                          <label for="f_status">
                              {{$t('title')}}
                          </label>
                          <input type="text" class="form-control" v-model="data.title" :class="validation && validation.title ? 'is-invalid' : ''" :placeholder="$t('title')">
                          <span v-if="validation && validation.title" class="fv-plugins-message-container invalid-feedback">
                              {{ validation.title[0] }}
                          </span>
                      </div>
                      <div class="col-6">
                          <label for="f_status">
                              {{$t('email')}}
                          </label>
<!--                        :disabled="isDisabled"-->
                          <input type="email"  class="form-control" v-model="data.email" :class="validation && validation.email ? 'is-invalid' : ''" :placeholder="$t('email')">
                          <span v-if="validation && validation.email" class="fv-plugins-message-container invalid-feedback">
                              {{ validation.email[0] }}
                          </span>
                      </div>
                      <div class="col-lg-12 mt-5">
                        <label>{{$t('body')}}</label>
                        <vue-editor v-model="data.body"></vue-editor>
                      </div>
                      <!-- <div class="col-lg-6 mt-5">
                          <label>{{$t('body')}}</label>
                          <textarea v-model="data.body" rows="10" cols="5" class="form-control" :class="validation && validation.body ? 'is-invalid' : ''" :placeholder="$t('body')">
                          </textarea>
                          <span v-if="validation && validation.body" class="fv-plugins-message-container invalid-feedback">
                              {{ validation.body[0] }}
                          </span>
                      </div> -->
                      <!-- <div class="col-lg-6 mt-5">
                          <label>{{$t('attachments')}}</label>
                          <upload-file
                                  @file="listenerAttachment"
                                  :inner-id="'attachmentCustomer'"
                                  :placeholder="$t('attachments')"
                                  :upload="dir_upload"
                                  :start-link="'base'"
                                  v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
                          </upload-file>
                      </div> -->
                      <div class="col-lg-12 mt-5">
                          <div class="form-group row">
                              <div class="col-lg-12 pt-8">
                                  <h5>{{$t('customers.attachments')}}</h5>
                              </div>
                              <div class="col-lg-12 mt-10">
                                  <upload-file
                                          @file="listenerAttachment"
                                          :inner-id="'attachment'"
                                          :placeholder="$t('customers.upload_attachment')"
                                          :upload="dir_upload"
                                          :start-link="'base'"
                                          :use-preview="false"
                                          v-bind:valueprop="{name:'attachment',path: attachment_url}" v-if="reloadUploadAttachment">
                                  </upload-file>
                              </div>
                              <div class="col-lg-12 mt-5">
                                  <div class="form-group">
                                      <table class="table">
                                          <thead>
                                          <tr>
                                              <th>{{ $t('customers.file')}}</th>
                                              <th></th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          <tr v-for="(att, index) in attachment_list" :key="index">
                                              <td>
                                                  <a target="_blank" :href="att.attachment_url" class="btn btn-link">{{ $t('customers.file_link')}} ({{att.attachment}})</a>
                                              </td>
                                              <td class="text-center">
                                                  <v-icon class="text-danger" @click="removeAttachmentFromList(index)">mdi-delete</v-icon>
                                              </td>
                                          </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-3"></div>
                    <div class="col-6 mb-8 text-center">
                      <button type="button" class="btn btn-primary btn-block" @click="save">{{ $t('send') }}</button>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {VueEditor} from "vue2-editor";

export default {
  components: {
      VueEditor
  },
  name: "form-cutomer",
  data() {
    return {
        mainRoute:'customers/customers',
        mainRouteDependency: 'base/dependency',
        dir_upload: 'emails',

      data: {
        title: "",
        email: "",
        body : "",
        fullname: "",
        customer_id: "",
      },
      isDisabled: true,
      customer: [],
      reloadUploadAttachment: true,
      attachment_url: null,
      attachment_list: [],
      isEditing: false,
      validation: null,

    };
  },
  watch: { 
  },
  methods: {
    save() {
      this.create();
    },

    create() {
    this.reloadUploadAttachment = false;
      ApiService.post(`customers/send_mail`, {
        attachments: this.attachment_list,
        ...this.data,
      }).then((response) => {
          this.validation = null;
          this.$successAlert(response.data.message);
          this.$router.push({name: 'customer.sendMail'});
        })
        .catch((error) => {
          this.validation = error.response ? error.response.data.errors : null;
            this.$errorAlert(error);
        });
      this.attachment_url = null;
      this.reloadUploadAttachment = true;
    },
  

    listenerAttachment(event) {
        if (event) {
            this.addAttachmentToList(event.pathDB, event.name);
        }
    },
    addAttachmentToList(attachment_url, attachment) {
        this.reloadUploadAttachment = false;
        this.attachment_url = null;
        this.attachment_list.push({id: null, attachment: attachment, attachment_url: attachment_url});
        this.reloadUploadAttachment = true;
    },
    removeAttachmentFromList(index) {
        this.attachment_list.splice(index, 1);
    },

    async getData() {
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.customer = response.data.data;
        this.data.email = this.customer.email;
        this.data.fullname = this.customer.fullname;
        this.data.customer_id = this.customer.id;
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.customers_management"),route:'/customers/customers'}, {title: this.$t("customer_mail")}]);

    this.getData();
  },
};
</script>
